import React from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { Layout, HeaderCentered, Seo } from "../components/Shared"
import { CoverYourHome } from "../components/Sections"

const ContactUs = ({ data }) => (
  <Layout>
    <Seo
      url={data.site && data.site.siteMetadata.url + "/contact-us"}
      title={data.headerAndSeo.seo && data.headerAndSeo.seo.title}
      description={data.headerAndSeo.seo.metaDescription.metaDescription}
      image={
        data.headerAndSeo.seo &&
        data.headerAndSeo.seo.image &&
        data.headerAndSeo.seo.image.file &&
        data.headerAndSeo.seo.image.file.url
      }
    />
    <HeaderCentered heading={data.headerAndSeo.headerTitle} breakEarly={true} />
    <main>
      <section className="wrapper-width wrapper-height contact-us">
        <ul className="contact-us-social-links">
          <li>
            <a
              href="https://www.facebook.com/homeassistrepairsandservicingltd/"
              className="social-icon-button icon-facebook"
              target="_blank"
              rel="noreferrer"
            >
              <span className="hidden">Facebook</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/homeassistrepairsservicing/"
              className="social-icon-button icon-instagram"
              target="_blank"
              rel="noreferrer"
            >
              <span className="hidden">Instagram</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/home-assist-repairs-and-servicing-ltd/"
              className="social-icon-button icon-linked-in"
              target="_blank"
              rel="noreferrer"
            >
              <span className="hidden">LinkedIn</span>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/repairHAservice"
              className="social-icon-button icon-twitter"
              target="_blank"
              rel="noreferrer"
            >
              <span className="hidden">Twitter</span>
            </a>
          </li>
        </ul>
        <section className="contact-us-methods">
          <a href="tel:0191 406 0888">
            <span className="icon-phone-call"></span>
            <span className="contact-us-method-details">0191 406 0888</span>
          </a>
          <a href="mailto:team@repairsandservicing.co.uk">
            <span className="icon-email"></span>
            <span className="contact-us-method-details">
              team@repairsandservicing.co.uk
            </span>
          </a>
        </section>
        <section className="contact-us-office-details">
          <ReactMarkdown
            source={data.headerAndSeo.headerParagraph.headerParagraph}
          />
        </section>
        <section className="contact-us-callback">
          <h2 className="heading-small">Prefer we call you?</h2>
          <Link to="/request-a-callback" className="button-primary">
            Request a callback
          </Link>
        </section>
        <section className="contact-us-callback">
          <h2 className="heading-small">Something not right?</h2>
          <Link to="/complaints" className="button-secondary">
            Submit a complaint
          </Link>
        </section>
      </section>
      <CoverYourHome backgroundColour="grey-lightest" />
    </main>
  </Layout>
)

export default ContactUs

export const pageQuery = graphql`
  query ContactUsContent {
    site {
      siteMetadata {
        url
      }
    }
    headerAndSeo: contentfulPages(page: { eq: "Contact us" }) {
      contentful_id
      page
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
      headerTitle
      headerParagraph {
        headerParagraph
      }
    }
  }
`
